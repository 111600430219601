import React from 'react';
import { Twemoji as EmojiRender } from "react-emoji-render";

const Emoji = (props) => {
  if (!props.symbol) return null;
  return (<EmojiRender svg text={props.symbol} style={{ marginRight: '10px', fontSize: '20px' }} />);
};


export default Emoji;
