/* eslint-disable camelcase */
import React from 'react';
import { Link } from 'react-router';

import config from '/const/config';
import { formatTitlePost } from '/lib/formatString';

import Feed from '/ui/organism/Feed';
import Flag from '../TranslateFeedItemFlag';

export default ({
  id,
  url,
  published,
  CurrentVersion,
}) => (
  <div key={id}>
    <Feed.Row paddingBottom={'0'}>
      <Feed.Cell width={2}>
        <Flag country={CurrentVersion.data.translate_language} />
      </Feed.Cell>
      <Feed.Cell width={11}>
        <Link to={`/post/${id}`} className="post-item">
          { formatTitlePost(CurrentVersion) }
        </Link>
      </Feed.Cell>
      <Feed.Cell width={5}>
        {!published
          ? (<a target="_blank" href={`${config.publicUrl}/preview/${id}`}>Черновик</a>)
          : (<div className="item viewPublicPost">
            <a href={`${config.publicUrl}${url || ''}`} target="_blank">
              <i className="icon world" />На сайте
            </a>
          </div>)
        }
      </Feed.Cell>
    </Feed.Row>
  </div>
);
