import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getPost,
} from '/actions/actions';
import {
  RelatedSearch,
} from '/features/RelatedEntity';
import {
  normalizeImgSrc,
} from '/components/SlateRichText/helpers';
import {
  InputError,
} from '/components/SlateRichText/atoms';
import {
  Link,
} from '/ui/atoms';
import config from '/const/config';
import constants from '/utils/constants';
import { searchableValue } from '/lib/searchableValue';
import {
  Container,
  Preview,
  Image,
  ImageStub,
  MaterialSpan,
  ItemContainer,
} from './styles';

@connect(
  (state, props) => ({
    syncedEntity: 'context',
    syncedId: props.id,
    post: state.post,
    searched: state.related.search_context || [],
  }),
  dispatch => ({
    getPost: (id) => {
      dispatch(getPost(id));
    },
  }),
)
class ContextField extends Component {
  componentDidMount() {
    const {
      context,
    } = this.props;

    if (context?.postId) {
      this.props.getPost(context.postId);
      this.props.onChange({
        isLoading: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { post } = this.props;
    const isPostChanged = prevProps.post !== this.props.post;
    const { Urls } = post;

    if (post.PublishedVersion && isPostChanged) {
      let contextType = constants.types[post.type] || 'Материал';

      if (post.PublishedVersion?.data?.translate_language === 'gb') {
        contextType = constants.typesEn[post.type] || 'Material';
      }

      this.props.onChange({
        context: {
          postId: post.id,
          title: post.PublishedVersion.title,
          url: Urls[0].canonical,
          type: contextType,
        },
        imgSrc: normalizeImgSrc(post.PublishedVersion.image, 'standard'),
        isLoading: false,
      });
    }
  }

  onChangeSearch = (option) => {
    this.clear();
    const { id: postId } = searchableValue.destructure(option.value);

    this.props.getPost(postId);
    this.props.onChange({
      isLoading: true,
    });
  };

  clear = () => {
    this.props.onChange({
      context: {
        postId: null,
        title: '',
        url: '',
      },
      imgSrc: '',
    });
  };

  render() {
    const {
      syncedEntity,
      error,
      isLoading,
      searched,
      context,
      imgSrc,
      id,
    } = this.props;

    const {
      postId,
      title,
      url,
      type,
    } = context;

    let options;

    if (searched) {
      options = searched.filter(el => el.id !== postId && !el.deleted);
    }

    return (
      <Container>
        <RelatedSearch
          onChange={this.onChangeSearch}
          placeholder="Введите заголовок или ссылку..."
          entity={syncedEntity}
          disabled={isLoading}
          id={id}
          searched={options}
          multi
        />
        {error ? (<InputError>{error}</InputError>) : null}
        <Preview>
          {imgSrc ? <Image
            src={imgSrc}
          /> : <ImageStub />}
          <ItemContainer>
            <MaterialSpan>{type}</MaterialSpan>
            <Link
              href={`${config.publicUrl}${url}`}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              {title || ''}
            </Link>
          </ItemContainer>
        </Preview>
      </Container>
    );
  }
}

export default ContextField;
