import deepEqueal from 'lodash/isEqual';
import omitBy from 'lodash/omitBy';
import {sanitizeFromSpaces} from '../../utils/postForm';
var E = {};

const blackList = {
	'createdAt': 1,
	'updatedAt': 1,
	'current_version': 1,
	'published_version': 1,
	'snapshots': 1,
	'urls': 1,
	'is_feature': 1,
  	'is_slate_rich_text': 1,
	'is_old_lead': 1,
	'is_old_editor': 1,
	'context_title': 1,
	'partner_id': 1,
	'imageShare': 1,
	'imageEntry': 1,
	'view_count': 1,
	'requireEditorRole': 1,
	'requireAdminRole': 1,
};

E.isEqualVersions = (currentVersion = {}, publishedVersion = {}) => {
	for (let prop in currentVersion){
		currentVersion[prop] = sanitizeFromSpaces(currentVersion[prop]);
	}
	for (let prop in publishedVersion){
		publishedVersion[prop] = sanitizeFromSpaces(publishedVersion[prop]);
	}
	if (publishedVersion){
		// const res = deepEqueal(
		// 	currentVersion,
		// 	publishedVersion
		// );

		var diff = omitBy(currentVersion, function(v, k) {
			if (k in blackList) return true;

			return deepEqueal(publishedVersion[k], v);
		});
		return Object.keys(diff).length == 0;
	}else{
		return false;
	}
}

module.exports = E;
