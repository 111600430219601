import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import Select from '/ui/atoms/Select';
import Label from '/ui/atoms/Label';
import { formatTitlePost } from '/lib/formatString';
import { fetchPostOrigin } from '../../store';

const options = [
  { label: 'Русский', value: 'ru' },
  { label: 'Английский', value: 'gb' },
  { label: 'Беларусский', value: 'by' },
  { label: 'Казахский', value: 'kz' },
  { label: 'Кыргызский', value: 'kg' },
  { label: 'Узбекский', value: 'uz' },
];

@connect(
  state => ({
    postOrigin: state?.related?.translateOrigin,
    postOriginPending: state?.related?.translateOriginPending,
  }),
)
class TranslateConfig extends Component {
  componentDidMount() {
    this.props.dispatch(fetchPostOrigin({ postId: this.props.postId }));
  }

  render() {
    return (<div className="ui segment">
      <Label>Язык перевода</Label>
      <Select
        name={this.props.input.name}
        onChange={this.props.input.onChange}
        value={this.props.input.value}
        options={options}
        defaultValue={options[0]}
      />
      <br />
      <Label>Оргинал материала</Label>
      { this.props.postOrigin?.CurrentVersion && <Link to={`/post/${this.props.postOrigin.id}`} >
        {formatTitlePost(this.props.postOrigin.CurrentVersion)}
      </Link>
      }
    </div>);
  }
}

export default TranslateConfig;
