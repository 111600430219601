/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import React from 'react';
import { connect } from 'react-redux';
import { Field as ReduxFormField, change } from 'redux-form';
import PropTypes from 'prop-types';
import {
  imageSelector,
  additionalImageSelector,
} from '/utils/form-upload-image-selectors';
import { SingleFileUploader } from '/features/FileUploader';

import { Label } from '/ui/atoms';
import { Field } from '/ui/molecules';

import ReduxFormInput from '/components/ReduxFormInput';

const BackgroundColorControl = () => (
  <Field>
    <Label>Цвет фона</Label>
    <ReduxFormField
      name="background_color"
      height="100"
      component={ReduxFormInput}
    />
  </Field>
);

const TextView = ({input}) => {
  if (input && input.value) {
    return (<div>{input.value}</div>)
  }
  return null;
};

@connect(
  (state, props) => ({
    image: imageSelector(state, props.form),
    additional_page_image: additionalImageSelector(state, props.form),
  }),
  (dispatch) => ({
    dispatch,
  }),
)

class Illustration extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (type) => (s3_path) => {
    const { dispatch, form } = this.props;

    const mapImageType = {
      normal: 'image',
      retina: 'retina_image',
      additional: 'additional_page_image',
      entry: 'imageEntry', // TODO: (new-image-generator) временная для загрузки изображение в CurrentVersion.image_entry
    };

    const imageField = mapImageType[type];

    dispatch(change(form, imageField, s3_path));
  };

  render() {
    const {
      // type,
      // TODO: (new-image-generator) добавить разные стратегии, соотвутсвующие типам материала
      // form,
      image,
      isBackgroundColor,
      tmpSocpic,
      richTextComponent,
      additionalImage,
      additional_page_image,
      disabled,
    } = this.props;

    if (disabled) {
      return (
          <>
            {image && (<>
              <img src={`${window.SERVER_SIDE_VARS.s3_url}${image}`} style={{width:'390px'}}/>
              <ReduxFormField
                name="image_title"
                component={TextView}
              />
            </>)}
            {additionalImage && <img src={`${window.SERVER_SIDE_VARS.s3_url}${additionalImage}`} style={{width:'390px'}}/>}
            {additional_page_image && <img src={`${window.SERVER_SIDE_VARS.s3_url}${additional_page_image}`} style={{width:'390px'}}/>}
            {tmpSocpic && <img src={`${window.SERVER_SIDE_VARS.s3_url}${tmpSocpic}`} style={{width:'390px'}}/>}
          </>
        )
    }

    return (
      <>
        <div className="post-upload-images__container">
          <Label>Заходная</Label>
          <ReduxFormField
            component={SingleFileUploader}
            name="image"
            id="image"
            key="image"
            title="Загрузить заходную картинку"
            file={image}
            handler={this.handleChange('normal')}
            customPath="i/e/"
            customDir
            accepted="image/*"
          />
          {image && (
            <Field>
              <ReduxFormField
                name="image_title"
                height="100"
                component={richTextComponent}
                placeholder="Описание заходной картинки..."
              />
            </Field>
          )}
          {(image || additional_page_image ) && (
            <>
              <Label>Шеринговая</Label>
              <ReduxFormField
                component={SingleFileUploader}
                name="additional_page_image"
                id="additional_page_image"
                key="additional_page_image"
                title="+ Загрузить отдельную картинку для шеринга"
                file={additional_page_image || tmpSocpic && window.SERVER_SIDE_VARS.s3_url + tmpSocpic || null}
                handler={this.handleChange('additional')}
                customPath="i/s/"
                customDir
                accepted="image/*"
              />
            </>
          )}
        </div>

        {tmpSocpic && !(additionalImage || image) && (
          <div className="ui vertical segment">
            <Label>Шеринговая</Label>
            <div className="ui fluid image">
              <img src={window.SERVER_SIDE_VARS.s3_url + tmpSocpic} />
            </div>
          </div>
        )}

        {isBackgroundColor && <BackgroundColorControl />}
      </>
    );
  }
}

Illustration.propTypes = {
  form: PropTypes.string,
};

export default Illustration;
